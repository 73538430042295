<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('md-makes.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('md-makes.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
        <v-card v-if="authUser.isAdmin">
          
        </v-card>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-md-make
            v-if="$admin.can('md-makes.create')"
            :filter-options="options"
          />
          <edit-md-make
            v-if="$admin.can('md-makes.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :md-make="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('md-makes.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateMdMake: () => import("./dialogs/CreateMdMake.vue"),
    EditMdMake: () => import("./dialogs/EditMdMake.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("make_type"),
          align: "left",
          sortable: false,
          value: "make_type",
          showAdmin: true,
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
        },
        ...(this.$admin.hasAccessTo("md-makes.delete") ||
        this.$admin.hasAccessTo("md-makes.edit")
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "mdMakes/isLoadingMdMakes",
      meta: "mdMakes/meta",
      list: "mdMakes/listMdMakes",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      if (this.authUser.isAdmin) {
        return [];
      } else {
        return [];
      }
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "md_makes",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("mdMakes/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(mdMake) {
      try {
        await this.$store.dispatch("mdMakes/destroy", mdMake);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
